.wrapper {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  width: 90%;
  max-width: 500px;
}

.slider-title {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  color: white;
}

form .speed-slider {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  position: relative;
  width: 100%;
  height: 50px;
  user-select: none;
}

form .speed-slider::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 100%;
  width: calc(100% * (2 / 3));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
}

form .speed-slider input,
form .speed-slider label {
  box-sizing: border-box;
  flex: 1;
  user-select: none;
  cursor: pointer;
}

form .speed-slider label {
  display: inline-block;
  position: relative;
  width: 20%;
  height: 100%;
  user-select: none;
}

form .speed-slider label::before {
  content: attr(current-speed);
  position: absolute;
  left: 50%;
  padding-top: 10px;
  transform: translate(-50%, 45px);
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0.85;
  transition: all 0.15s ease-in-out;
}

form .speed-slider label::after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  background: #fff;
  border-radius: 50%;
  pointer-events: none;
  user-select: none;
  z-index: 1;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

form .speed-slider label:hover::after {
  transform: translate(-50%, -50%) scale(1.25);
}

form .speed-slider input {
  display: none;
}

form .speed-slider input:checked + label::before {
  font-weight: 800;
  opacity: 1;
}

form .speed-slider input:checked + label::after {
  border-width: 4px;
  transform: translate(-50%, -50%) scale(0.75);
}

form .speed-slider input:checked ~ .slider-position {
  opacity: 1;
}

form .speed-slider input:checked:nth-child(1) ~ .slider-position {
  left: 16.7%;
}

form .speed-slider input:checked:nth-child(3) ~ .slider-position {
  left: 50%;
}

form .speed-slider input:checked:nth-child(5) ~ .slider-position {
  left: 83.3%;
}

form .speed-slider .slider-position {
  display: block;
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background: #000;
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  opacity: 0;
  z-index: 2;
}

form:valid .speed-slider input + label::before {
  transform: translate(-50%, 45px) scale(0.9);
  transition: all 0.15s linear;
}

form:valid .speed-slider input:checked + label::before {
  transform: translate(-50%, 45px) scale(1.1);
  transition: all 0.15s linear;
}

form + button {
  display: block;
  position: relative;
  margin: 56px auto 0;
  padding: 10px 20px;
  appearance: none;
  transition: all 0.15s ease-in-out;
  font-family: inherit;
  font-size: 24px;
  font-weight: 600;
  background: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  outline: 0;
  user-select: none;
  cursor: pointer;
}

form + button:hover {
  background: #000;
  color: #fff;
}

form + button:hover:active {
  transform: scale(0.9);
}

form + button:focus {
  background: #4caf50;
  border-color: #4caf50;
  color: #fff;
  pointer-events: none;
}

form + button:focus::before {
  animation: spin 1s linear infinite;
}

form + button::before {
  display: inline-block;
  width: 0;
  opacity: 0;
  content: "\f3f4";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  margin-right: 0;
  transform: rotate(0deg);
}

form:invalid + button {
  pointer-events: none;
  opacity: 0.25;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
  to {
    transform: rotate(360deg);
    width: 24px;
    opacity: 1;
    margin-right: 12px;
  }
}

label {
  color: white;
}
