.dropdown {
  background-color: white;
  border: 2px solid dodgerblue;
  border-radius: 20px;
  padding: 2px 15px;
}
.dropdown select:hover {
  cursor: pointer;
}
.dropdown select {
  font-size: 1rem;
  font-weight: normal;
  padding: 10px 15px 10px 2px;
  border: none;
  background-color: transparent;
}
.dropdown select:focus {
  outline: none;
}
