.button {
  color: black;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  padding: 15px 15px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
}

.sort {
  background-color: #18f2b2;
  box-shadow: 0 2px #f082ac;
}

.sort:hover {
  color: white;
  background-color: #e7387b;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.sort:active {
  box-shadow: 0 2px #f082ac;
  transform: translateY(2px);
}

.newArray {
  background-color: #18f2b2;
  box-shadow: 0 2px powderblue;
}

.newArray:hover {
  color: white;
  background-color: #2a75ff;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.button:active {
  box-shadow: 0 2px powderblue;
  transform: translateY(2px);
}
