body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #0f0e13;
}

@import url("./components/styles/button.css");

@import url("./components/styles/dropdown.css");

@import url("./components/styles/slider.css");

@import url("./components/styles/visualizer.css");
