.header {
  position: relative;
  padding: 0px 25px 30px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #89043d;
}

.sortingBars {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.bars {
  width: 10px;
  background-color: #ff7f50;
  display: inline-block;
  border-radius: 10px;
}

.bar-containers {
  padding: 0px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.algoInfo {
  width: 60%;
  height: 70px;
  margin: auto;
  border: 3px solid #b2abf2;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.algoInfo div {
  color: #ff581c;
  font-size: 25px;
  font-weight: 800;
}

.algoInfoDetails {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 80%;
  margin: auto;
  border: 3px solid #b2abf2;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.algoInfoDetails div {
  color: #ff581f;
  font-size: 20px;
  font-weight: 600;
}

.reg {
  display: flex;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.timer {
  display: flex;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 30px;
  padding-bottom: 20px;
}

.bar-label {
  color: white;
}
